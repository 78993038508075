import React from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import './App.css'
const MainBody = React.lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import ("./MainBody/MainBody")), 2000);
    });
});
const Main = React.lazy(() => {return new Promise(resolve => {
    setTimeout(() => resolve(import("./Main/Main")), 2000);
  });});

function App() {
   
    return (
        <div >
           <Router>
                <Routes>
                    <Route
                        exact
                        path='/'
                        element={< React.Suspense fallback = { < Loader />
                    } > <Main/> </React.Suspense>}/>
                </Routes>
                <Routes>
                    <Route
                        exact
                        path='/Dashboard'
                        element={< React.Suspense fallback = { < Loader />
                    } > <MainBody/> </React.Suspense>}/>
                </Routes>
            </Router>

        </div>
    );
}
function Loader(){
    return(
      <div className="loader animate__animated animate__fadeIn" style={{'paddingTop' : '5%'}}>
          <center><img src="/image/loading.gif" /></center>
      </div>
    )
  }
export default App;
